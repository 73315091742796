import {Criteria} from "@zertifier/criteria";
import {HttpUserTypes} from './HttpUserTypes';

export class HttpUtils {
	public static convertCriteriaToQueryParams(criteria: Criteria): string {
    const plainObject = criteria.toPlainObject();
    return `criteria=${encodeURIComponent(JSON.stringify(plainObject))}`;
	}

  public static getTypeByRoleName(role: "ADMIN_GLOBAL" | "ADMIN" | "STUDENT" | "TEACHER" | string): HttpUserTypes{
    switch (role){
      case "ADMIN_GLOBAL": return 'admins'
      case "ADMIN": return 'admins'
      case "STUDENT": return 'students'
      case "TEACHER": return 'teachers'
      default: return "students"
    }
  }
}
